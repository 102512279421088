<template>
  <div class="card p-10">
    <div class="d-flex justify-content-between mb-5">
      <h1>編輯草稿</h1>
      <button @click="goBack()" type="button" class="btn btn-light">
        返回
      </button>
    </div>
    <div>
      <el-form
        @submit.prevent="submit()"
        :model="form"
        :rules="rules"
        ref="formRef"
        class="form"
      >
        <label class="fs-6 fw-bold mb-2">
          <span class="required">起訖時間</span>
        </label>
        <el-form-item prop="datePicker">
          <el-date-picker
            v-model="form.datePicker"
            type="datetimerange"
            range-separator="至"
            start-placeholder="開始日期"
            end-placeholder="結束日期"
          >
          </el-date-picker>
        </el-form-item>

        <label class="fs-6 fw-bold mb-2">
          <span class="required">標題</span>
        </label>
        <el-form-item prop="title">
          <el-input v-model="form.title" placeholder="請輸入標題"></el-input>
        </el-form-item>

        <label class="fs-6 fw-bold mb-2">
          <span>簡介</span>
        </label>
        <el-form-item>
          <el-input
            type="textarea"
            :rows="2"
            placeholder="請輸入簡介"
            v-model="form.summary"
          ></el-input>
        </el-form-item>

        <label class="fs-6 fw-bold mb-2">
          <span>內容</span>
        </label>
        <el-form-item>
          <editor
            v-model="form.content"
            :init="tinymceInit"
            api-key="3x7t4hj96hg3pwokkputtnt7xbqzy3pc0dq436suk0o2nx17"
          />
        </el-form-item>

        <label class="fs-6 fw-bold mb-2">
          <span>封面照</span>
        </label>
        <el-form-item>
          <input
            type="file"
            class="form-control"
            id="inputGroupFile01"
            accept="image/*"
            @input="onCoverPhotoInput($event)"
          />
          <div v-if="!isCoverPhotoEmpty" style="width: 300px">
            <img :src="showCoverPhoto" class="img-fluid my-5" alt="..." />
          </div>
        </el-form-item>

        <label class="fs-6 fw-bold mb-2">
          <span>附檔</span>
        </label>
        <el-form-item>
          <input
            type="file"
            class="form-control"
            id="inputGroupFile02"
            multiple="multiple"
            accept="image/*, .pdf"
            @input="onFilesInput($event)"
          />
        </el-form-item>
        <template v-for="item in fileList" :key="item.url">
          <div style="font-size: 16px; margin: 10px 0">
            <i
              @click="cancelFile(item.url)"
              class="bi bi-x-circle me-1 cancelIcon"
            ></i>
            <a :href="item.url">{{ item.name }}</a>
          </div>
        </template>

        <div class="d-flex justify-content-end">
          <button class="btn btn-success" type="submit">送出</button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script lang='ts'>
import { defineComponent, reactive, ref } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import localstorage from "@/core/services/LocalstorageService";
import Editor from "@tinymce/tinymce-vue";
import {
  getTargetAnnouncement,
  updateDraft,
} from "@/core/services/api/announcement";
import {
  uploadAnnouncementsCoverPhoto,
  uploadAnnouncementsFile,
  deleteAnnouncementsFile,
} from "@/core/services/api/file";
import { getLocalTimeString, getUtcTimeString } from "@/core/utils/timeString";
import { useRoute } from "vue-router";
import { getFilePath } from "@/core/utils/file";

export default defineComponent({
  components: {
    editor: Editor,
  },
  setup() {
    let originalCoverPhoto: any = "";
    let announcementFiles: any;
    const fileList: any = reactive([]);
    const route = useRoute();
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    const tinymceInit = {
      language_url: "/tinymce/langs/zh_TW.js",
      language: "zh_TW",
      height: 300,
    };
    const formRef = ref<null | HTMLFormElement>(null);
    const rules = reactive({
      datePicker: [
        {
          required: true,
          message: "請選擇起訖時間",
          trigger: "blur",
        },
      ],
      title: [
        {
          required: true,
          message: "請填寫標題",
          trigger: "blur",
        },
      ],
    });
    const form: any = reactive({
      datePicker: [],
      title: "",
      summary: "",
      content: "",
      coverPhoto: "",
      files: [],
    });
    let isCoverPhotoEmpty = ref(true);
    let showCoverPhoto = ref("");

    const onCoverPhotoInput = (file) => {
      if (!file.target.files[0]) {
        form.coverPhoto = "";
        showCoverPhoto.value = "";
        isCoverPhotoEmpty.value = true;
      } else {
        form.coverPhoto = file.target.files[0];
        showCoverPhoto.value = URL.createObjectURL(file.target.files[0]);
        isCoverPhotoEmpty.value = false;
      }
    };

    const onFilesInput = (inputFile) => {
      let inputFileLength = inputFile.target.files.length;
      let pushFiles: any = [];
      for (const o in inputFile.target.files) {
        pushFiles.push(inputFile.target.files[o]);
      }
      pushFiles.splice(inputFileLength, pushFiles.length);
      pushFiles.forEach((o) => {
        fileList.push({
          name: o.name,
          url: URL.createObjectURL(o),
          file: o,
          isLocal: true,
        });
      });
    };

    const goBack = () => {
      history.back();
    };

    const setformData = async () => {
      let announcementGuid: any = route.params.announcementGuid;
      let announcement = await getTargetAnnouncement(
        currentSpaceGuid!,
        announcementGuid
      );
      form.datePicker = [
        getLocalTimeString(announcement.beginTime),
        getLocalTimeString(announcement.endTime),
      ];
      form.title = announcement.title;
      form.summary = announcement.summary;
      form.content = announcement.content;
      if (announcement.coverPhoto != "") {
        isCoverPhotoEmpty.value = false;
        showCoverPhoto.value = getFilePath(announcement.coverPhoto);
      }
      originalCoverPhoto = announcement.coverPhoto;
      fileList.splice(0);
      announcementFiles = announcement.files;
      announcement.files.forEach((o) => {
        fileList.push({
          name: o.file.name,
          guid: o.file.guid,
          url: getFilePath(o.file.guid),
          isLocal: false,
        });
      });
    };

    const cancelFile = (itemUrl) => {
      let filterFile = fileList.filter((o) => o.url !== itemUrl);
      fileList.splice(0, fileList.length, ...filterFile);
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value!.validate(async (valid) => {
        if (valid) {
          await Swal.fire({
            title: "您確定要更新草稿嗎?",
            confirmButtonText: "確定",
            denyButtonText: `取消`,
          }).then(async (result) => {
            if (result.isConfirmed) {
              // 公告guid
              let announcementGuid: any = route.params.announcementGuid;

              // 更新草稿
              let updateDraftRequest = {
                title: form.title,
                content: form.content,
                summary: form.summary,
                beginTime: getUtcTimeString(form.datePicker[0]),
                endTime: getUtcTimeString(form.datePicker[1]),
              };
              await updateDraft(
                currentSpaceGuid!,
                announcementGuid,
                updateDraftRequest
              );

              // 修改公告封面照
              if (form.coverPhoto !== "") {
                if (originalCoverPhoto !== "") {
                  await deleteAnnouncementsFile(
                    announcementGuid,
                    originalCoverPhoto
                  );
                }
                let formData = new FormData();
                formData.set("file", form.coverPhoto, form.coverPhoto.name);
                await uploadAnnouncementsCoverPhoto(announcementGuid, formData);
              }

              // 本地檔案上傳
              let localFile = fileList.filter((o) => o.isLocal);
              if (localFile.length !== 0) {
                for (const o of localFile) {
                  let formData = new FormData();
                  formData.set("file", o.file, o.name);
                  await uploadAnnouncementsFile(announcementGuid, formData);
                }
              }

              // 原始檔案刪除
              let unlocalFile = fileList.filter((o) => !o.isLocal);
              if (unlocalFile.length < announcementFiles.length) {
                let unlocalFileGuids = unlocalFile.map((o) => o.guid);
                for (const o of announcementFiles) {
                  if (unlocalFileGuids.indexOf(o.file.guid) == -1) {
                    await deleteAnnouncementsFile(
                      announcementGuid,
                      o.file.guid
                    );
                  }
                }
              }

              await Swal.fire("草稿更新成功!", "", "success");
              goBack();
            }
          });
        }
      });
    };

    const init = async () => {
      await setformData();
    };
    init();

    return {
      rules,
      formRef,
      form,
      submit,
      tinymceInit,
      onCoverPhotoInput,
      onFilesInput,
      isCoverPhotoEmpty,
      showCoverPhoto,
      goBack,
      fileList,
      cancelFile,
    };
  },
});
</script>

<style scoped>
.cancelIcon {
  font-size: 16px;
  cursor: pointer;
}

.cancelIcon:hover {
  font-size: 16px;
  color: red;
}
</style>